import React, { Component } from "react";
import { ListGroup, ButtonGroup, Button, Col, Row, Accordion } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RemediationForm from "./RemediationForm";
import { connect } from "react-redux";
import { fetchData } from '../../../actions';
import { HomeService } from '../../../services/HomeService'


const mapDispatchToProps = dispatch => {
    return {
        fetchData: data => dispatch(fetchData(data)),
    }
}
class Remediation extends Component {
    constructor(props) {
        super(props);
        this.handleDelete = this.handleDelete.bind(this);
    }
    handleDelete(e) {
        const id = this.props.remediation.id
        this.props.fetchData({
            service: HomeService,
            method: 'deleteRemediation',
            props: id
        })
    }

    render() {
        const remediation = this.props.remediation;
        return <ListGroup.Item > <Row>
            <Col md={9}>{this.props.remediation.description}</Col>
            <Col md={2}>
                <ButtonGroup aria-label="Basic example">
                    <Accordion.Toggle as={Button} eventKey={this.props.remediation.id} ><FontAwesomeIcon icon={['fas', 'edit']} /></Accordion.Toggle>
                    <Button variant="danger" onClick={this.handleDelete}><FontAwesomeIcon icon={['fas', 'trash-alt']} /></Button>
                </ButtonGroup>
            </Col>
        </Row>
            <Accordion.Collapse eventKey={remediation.id}>
                <RemediationForm
                    description={remediation.description}
                    suggestedAction={remediation.suggestedAction}
                    evidence={remediation.evidence}
                    id={remediation.id} />
            </Accordion.Collapse>
        </ListGroup.Item >
    }
}

export default connect(null, mapDispatchToProps)(Remediation);