import React, { Component } from "react";
import { Form, Button, InputGroup, Accordion, Col, Row } from "react-bootstrap";
import { HomeService } from '../../../services/HomeService'
import { connect } from "react-redux";
import { fetchData, setValueNewRemediation, editRemediation } from '../../../actions'


const mapDispatchToProps = dispatch => {
    return {
        fetchData: data => dispatch(fetchData(data)),
        setValueNewRemediation: data => dispatch(setValueNewRemediation(data)),
        editRemediation: data => dispatch(editRemediation(data))
    }
}
const mapStateToProps = (state) => {
    return {
        meetingInfo: state.meetingInfo,
        options: state.options[0].options,
        newRemediations: state.newRemediations
    }
}

class RemediationForm extends Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.clearState = this.clearState.bind(this);
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (this.props.id == null) this.props.setValueNewRemediation({
            [name]: value
        }); else this.props.editRemediation({
            id: this.props.id,
            [name]: value
        })
    }
    clearState() {
        this.props.setValueNewRemediation({
            description: '',
            suggestedAction: '',
            evidence: 0,
            id: undefined
        })
    }

    handleSave(e) {
        const data = this.props;
        const payload = data.id === undefined ? this.props.newRemediations : this.setPayload(data);
        payload.WorkItem = this.props.meetingInfo.workItem;
        this.props.fetchData({
            service: HomeService,
            method: 'updateRemediation',
            props: payload
        })
        this.clearState()
    }

    setPayload(data) {
        const payload = {};
        payload.Id = data.id != null ? data.id : undefined;
        payload.Evidence = data.evidence !== 0 ? data.evidence : undefined;
        payload.SuggestedAction = data.suggestedAction !== '' ? data.suggestedAction : undefined;
        payload.Description = data.description !== '' ? data.description : undefined;
        return payload;
    }

    render() {
        let data = { suggestedAction: '', description: '', evidence: 0 };
        data = this.props.id == null ? this.props.newRemediations : this.props;
        const opts = this.props.options.map(o => <option key={o.value} value={o.value} >{o.text}</option>)
        return <Form className="remediation-form">
            <Form.Group as={Row} controlId="RemediationForm.description">
                <Col>
                    <Form.Label><strong>Description</strong></Form.Label>
                    <Form.Control as="textarea" rows="3" name="description" value={data.description} onChange={this.handleInputChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="RemediationForm.action">
                <Col>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Suggested Action</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" name="suggestedAction" value={data.suggestedAction} onChange={this.handleInputChange}
                            aria-label="Suggested Action"
                        />
                    </InputGroup>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="RemediationForm.evidence">
                <Col>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Evidence Required</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" name="evidence" value={data.evidence} onChange={this.handleInputChange}
                            aria-label="Evidence Required">
                            <option value={0}>Select Evidence</option>
                            {opts}
                        </Form.Control>
                    </InputGroup>
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col md={{ offset: '9' }}>
                    <Accordion.Toggle as={Button} eventKey={'new'} variant="primary" block onClick={this.handleSave}>
                        Save
                </Accordion.Toggle>
                </Col>
            </Form.Group>

        </Form >
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemediationForm);