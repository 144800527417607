import React, { Component } from 'react'
import { Container, Alert } from 'react-bootstrap'



export class InspectionInfo extends Component {
    render() {
        return <Container>
            <Alert className="inspection-info-card" variant="info">
                <p>Inspection Type: {this.props.inspection.type}</p>
                <p>Elements to be inspected: {this.props.inspection.elements}</p>
            </Alert>
        </Container>
    }
}