import React, { Component } from 'react'
import Media from 'react-bootstrap/Media'
import moment from 'moment'
import { Logo } from './Logo'


export class MeetingInfo extends Component {
    render() {
        return <Media className="meeting-info-media">
            <Logo className="meeting-info-logo " />
            <Media.Body className="meeting-info-body">
                <h3>{moment(this.props.info.visitDate).format('dddd Do MMMM YYYY, HH:mm')}</h3>
                <h5>{this.props.info.visitLocation}</h5>
                <strong>Surveyor: {this.props.info.surveyor}</strong>
            </Media.Body>
        </Media>
    }
}