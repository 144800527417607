import React, { useContext } from "react";
import { useAccordionToggle, AccordionContext, Button } from "react-bootstrap";
export function ToggleAccordionBtn({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <Button
            variant="primary"
            block
            hidden={isCurrentEventKey}
            onClick={decoratedOnClick}
        >
            {children}
        </Button>
    );
}

