/*
 * action types
 */

export const ADD_REMEDIATION = 'ADD_REMEDIATION';
export const EDIT_REMEDIATION = 'EDIT_REMEDIATION';
export const SET_REMEDIATIONS = 'SET_REMEDIATIONs';
export const DELETE_REMEDIATIONS = 'DELETE_REMEDIATIONS';

export const SET_VALUE_NEW_REMEDIATION = 'SET_VALUE_NEW_REMEDIATION';

export const ADD_ALERTS = 'ADD_ALERTS';
export const REMOVE_ALERT = 'REMOVE_ALERT';


export const SET_MEETINGINFO = 'SET_MEETINGINFO'
export const UPDATE_MEETINGINFO = 'UPDATE_MEETINGINFO'

export const REQUEST_DATA = 'REQUEST_DATA';
export const RECEIVE_DATA = 'RECEIVE_DATA';

export const SET_OPTIONS = 'SET_OPTIONS';
export const UPDATE_OPTIONS = 'UPDATE_OPTIONS';



/*
 * action creators
 */
export function setRemediations(remediations) {
    return { type: SET_REMEDIATIONS, remediations }
}

export function addRemediation(remediation) {
    return { type: ADD_REMEDIATION, remediation }
}
export function editRemediation(remediation) {
    return { type: EDIT_REMEDIATION, remediation }
}
export function deleteRemediation(id) {
    return { type: DELETE_REMEDIATIONS, id }
}

export function setValueNewRemediation(remediation) {
    return { type: SET_VALUE_NEW_REMEDIATION, remediation }
}

export function addAlert(alert) {
    const newAlert = { ...alert, show: true, time: Date.now() };
    return { type: ADD_ALERTS, newAlert }
}
export function removeToast(index) {
    return { type: REMOVE_ALERT, index }
}

export function setMeetingInfo(meetingInfo) {
    return { type: SET_MEETINGINFO, meetingInfo }
}
export function updateMeetingInfo(meetingInfo) {
    return { type: UPDATE_MEETINGINFO, meetingInfo }
}

export function setOptions(options) {
    return { type: SET_OPTIONS, options }
}

export function updayeOptions(options) {
    return { type: UPDATE_OPTIONS, options }
}

export function receiveData() {
    return { type: RECEIVE_DATA }
}
export function requestData() {
    return { type: REQUEST_DATA }
}


export function fetchData(request) {
    return function (dispatch) {
        try {
            dispatch(requestData());
            request.service[request.method](request.props).then(data => {

                switch (request.method) {
                    case 'updateRemediation':
                    case 'updateMeetingInfo':
                    case 'getWorkItem':
                        if (data != null) {
                            dispatch(setMeetingInfo({
                                id: data.id,
                                surveyor: data.surveyor,
                                visitDate: data.visitDate,
                                visitLocation: data.visitLocation,
                                inviteCode: data.inviteCode,
                                inspectionType: data.inspectionType,
                                elements: data.elements,
                                generalObservations: data.generalObservations,
                                workItem: data.id
                            }));
                        } else dispatch(addAlert({ alertName: 'Error', alertText: 'No Data Received' }));

                        if (data.remediations != null) dispatch(setRemediations([...data.remediations]));
                        else dispatch(addAlert({ alertName: 'Error', alertText: 'No Remediations Received' }));
                        dispatch(receiveData());
                        break;
                    case 'getOptionSet':
                        dispatch(setOptions({ entityName: request.props.entityName, attribute: request.props.attribute, options: data }));
                        dispatch(receiveData());
                        break;
                    case 'deleteRemediation':
                        dispatch(deleteRemediation(request.id));
                        dispatch(setRemediations([...data.remediations]));
                        console.log(data);
                        dispatch(receiveData());
                        break;
                    default:
                        dispatch(receiveData());
                        break;
                }
            });
        } catch (e) {
            const alert = { alertName: `Error:${e.name}`, alertText: e.message }

            dispatch(addAlert(alert));
            dispatch(receiveData());

        }
    }

}