import React, { Component } from "react";
import { connect } from 'react-redux';
import { ListGroup, Button, Spinner } from "react-bootstrap";
import Remediation from "./Remediation";

function mapStateToProps(state) {
    return {
        remediations: state.remediations,
        dataFlag: state.dataFlag
    }
}

class ListRemediations extends Component {
    render() {
        const remediations = this.props.remediations;
        if (this.props.dataFlag === 'received') {
            if (remediations[0] !== null) {
                return <ListGroup variant="flush">
                    {remediations.map(r => <Remediation remediation={r} key={r.id} />)}
                </ListGroup>
            } else return <p>No Remediations</p>
        } else {
            return <Button variant="secondary" block disabled>
                <Spinner
                    as="span"
                    animation="border"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                />
                <span className="sr-only">Loading...</span>
            </Button>
        }
    }
}

export default connect(mapStateToProps)(ListRemediations);