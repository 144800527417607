import { combineReducers } from 'redux'
import { ADD_REMEDIATION, DELETE_REMEDIATIONS, EDIT_REMEDIATION, SET_MEETINGINFO, SET_VALUE_NEW_REMEDIATION, UPDATE_MEETINGINFO, SET_REMEDIATIONS, RECEIVE_DATA, REQUEST_DATA, SET_OPTIONS, UPDATE_OPTIONS, ADD_ALERTS, REMOVE_ALERT } from './actions';

function dataFlag(state = '', action) {
    switch (action.type) {
        case RECEIVE_DATA:
            return 'received'
        case REQUEST_DATA:
            return 'request sent'
        default:
            return state;
    }
}

function meetingInfo(state = { id: null, surveyor: null, visitDate: null, visitLocation: null, inviteCode: null, workItem: null, inspectionType: null, elements: null, generalObservations: null }, action) {
    switch (action.type) {
        case SET_MEETINGINFO:
            return { ...action.meetingInfo };
        case UPDATE_MEETINGINFO:
            return { ...state, ...action.meetingInfo };
        default:
            return state;
    }

}

function options(state = [{ options: [] }], action) {
    switch (action.type) {
        case SET_OPTIONS:
            return [action.options]
        case UPDATE_OPTIONS:
            return [...state, ...[action.options]];
        default:
            return state;
    }

}
function toasts(state = [], action) {
    switch (action.type) {
        case ADD_ALERTS:
            console.log(action.newAlert)
            return [...state, action.newAlert]
        case REMOVE_ALERT:
            const nState = state.map((t, index) => (action.index !== index) ? t : undefined)
            return nState[0] === undefined ? null : nState;
        default:
            return state;
    }
}

function remediations(state = [], action) {
    switch (action.type) {
        case SET_REMEDIATIONS:
            return [...action.remediations];
        case ADD_REMEDIATION:
            return [...state, action.remediation]
        case EDIT_REMEDIATION:
            return state.map(r => r.id === action.remediation.id ? { ...r, ...action.remediation } : r)
        case DELETE_REMEDIATIONS:
            return state.filter(r => r.id !== action.id);
        default:
            return state;
    }
}
function newRemediations(state = {}, action) {
    switch (action.type) {
        case SET_VALUE_NEW_REMEDIATION:
            return { ...state, ...action.remediation }
        default:
            return state;
    }
}
const appReducers = combineReducers({ remediations, meetingInfo, options, toasts, dataFlag, newRemediations });
export default appReducers;