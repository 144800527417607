import React, { Component } from 'react'
import Image from 'react-bootstrap/Image'


export class Logo extends Component {

    render() {
        return <Image className={this.props.className} src="/StromaBC.png" alt="Logo" width={200}
            height={100} />
    }
}