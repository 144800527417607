import axios from 'axios';


const apiURL = 'api/'
export class APIService {

    static get(path, config = null) {
        return axios({
            method: 'GET',
            url: apiURL + path,
            ...config
        })
    }
    static post(path, payload, config = null) {
        return axios({
            method: 'POST',
            url: apiURL + path,
            data: payload,
            ...config
        })
    }
}