import React, { Component } from "react";
import { Container, Accordion, Card } from "react-bootstrap";
import ObservationForm from "./ObservationForm";

export class ObservationControl extends Component {
    render() {
        return <Container>

            <Card>
                <Card.Body>
                    <Card.Title></Card.Title>
                        <ObservationForm />
                </Card.Body>
            </Card>
            </Container>
    }
}