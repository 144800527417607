import React, { Component } from 'react';
import Call from './Call/Call';
import { Col, Container, Row } from 'react-bootstrap';
import Sidebar from './Sidebar';
import { HomeService } from '../services/HomeService';
import { Header } from './Header';
import './Home.css';
import { connect } from 'react-redux';
import { fetchData } from '../actions'
import Loader from './Call/Loader/Loader';

const mapStateToProps = state => {
    return {
        meetingInfo: state.meetingInfo
    }
}
const mapDispatchToProps = dispatch => {
    return {
        fetchData: data => dispatch(fetchData(data)),
    }
}


class Home extends Component {
    componentDidMount() {
        const workItem = new URLSearchParams(this.props.location.search).get("workitem") ?? 'D026FE93-E562-EA11-A811-000D3A86D6BA';
        this.props.fetchData({ service: HomeService, method: 'getWorkItem', props: { WorkItem: workItem } });
        this.props.fetchData({ service: HomeService, method: 'getOptionSet', props: { attribute: 'sbc_evidencerequired', entityName: 'sbc_observation' } });
    };
    render() {
        const meetingInfo = this.props.meetingInfo;
        const callTag = (meetingInfo.inviteCode !== null) ? <Call oncall={true} invite={meetingInfo.inviteCode} display={meetingInfo.surveyor} /> : <Loader />

        return (
            <Container className="home-container min-vh-100" fluid>
                {/* <Alerts /> */}
                <Row style={{ height: '12vh' }}>
                    <Header info={{ ...meetingInfo }} />
                </Row>
                <Row style={{ minHeight: '80vh' }} className="main-row h-100">
                    <Col sm={3}>
                        <Sidebar />
                    </Col>
                    <Col sm={9}>
                        {callTag}
                    </Col>

                </Row>
            </Container>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);