import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Container } from 'react-bootstrap';
import './Sidebar.css';
import { InspectionInfo } from './InspectionInfo';
import { RemediationControl } from './RemediationControl';
import { ObservationControl } from './ObservationControl';
import Tabs from "./Tabs/Tabs";
import "./Tabs/Tab.css";

const mapStateToProps = state => {
    return {
        meetingInfo: state.meetingInfo,
    }
}

class Sidebar extends Component {
    render() {
        // const meetingInfo = this.props.meetingInfo;
        return <Container className="sidebar-container" fluid>
            <Row><InspectionInfo inspection={{ type: this.props.meetingInfo.inspectionType, elements: this.props.meetingInfo.elements }} /></Row>
        
            <Tabs>
                <div label="General">
                    <Row><ObservationControl /></Row>
                </div> 
                <div label="Remediations">
                    <Row><RemediationControl /></Row>
              </div> 
            </Tabs>
        </Container>
    }
}

export default connect(mapStateToProps)(Sidebar);