import React from 'react'
import { Spinner, Col, Button, Row } from 'react-bootstrap'
import './Loader.css'

const Loader = () => <Row className="mh-100">

    <Col>
        <Button variant="secondary" block disabled>
            <Spinner
                as="span"
                size="xl"

                role="status"
                aria-hidden="true"
                animation="grow"
            />
            <span className="sr-only">Loading...</span>
        </Button>
    </Col>
</Row>

export default Loader