import { APIService } from "./APIService";

const servicePath = 'home'
export class HomeService extends APIService {
    static async getWorkItem(props) {
        const apiMethodName = 'getworkitem'
        const path = `${servicePath}/${apiMethodName}/${props.WorkItem}`
        const res = await this.get(path);
        return res.data;
    }

    static async updateRemediation(Remediation) {
        console.log(Remediation);
        const apiMethodName = 'UpdateRemediation'
        const path = `${servicePath}/${apiMethodName}`
        const res = await this.post(path, Remediation);
        return res.data;
    }
    static async updateMeetingInfo(WorkItem) {
        const apiMethodName = 'UpdateWorkItem'
        const path = `${servicePath}/${apiMethodName}`
        const res = await this.post(path, WorkItem);
        return res.data;
    }

    static async deleteRemediation(id) {
        const apiMethodName = 'DeleteRemediation'
        const path = `${servicePath}/${apiMethodName}/${id}`
        const res = await this.get(path);
        return res.data;
    }

    static async getOptionSet({ entityName, attribute }) {
        const apiMethodName = 'GetOptionSet'
        const path = `${servicePath}/${apiMethodName}/${entityName}/${attribute}`
        const res = await this.get(path);
        return res.data;
    }
}
