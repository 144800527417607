import React, { Component } from "react";
import { Form, Button, InputGroup, Accordion, Col, Row } from "react-bootstrap";
import { HomeService } from '../../../services/HomeService'
import { connect } from "react-redux";
import { fetchData, updateMeetingInfo } from '../../../actions'


const mapDispatchToProps = dispatch => {
    return {
        fetchData: data => dispatch(fetchData(data)),
        updateMeetingInfo: data => dispatch(updateMeetingInfo(data))
    }
}
const mapStateToProps = (state) => {
    return {
        meetingInfo: state.meetingInfo,
        options: state.options[0].options,
    }
}

class ObservationForm extends Component {
    constructor(props) {
        super(props);
        this.state = { textValue: '' };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.clearState = this.clearState.bind(this);
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({ textValue: value });
        this.props.updateMeetingInfo({
            generalObservations: value
        });
    }
    clearState() {
        this.setState({ textValue: '' });
    }

    handleSave(e) {
        const data = this.props;
        const payload = this.props.meetingInfo;
       // payload.generalObservations = data.generalObservations;
        this.props.fetchData({
            service: HomeService,
            method: 'updateMeetingInfo',
            props: payload
        })
        this.clearState()
    }



    render() {
        let data = { generalObservations: this.props.meetingInfo.generalObservations};
        return <Form className="remediation-form">
            <Form.Group as={Row} controlId="ObservationForm.generalObservations">
                <Col>
                    <Form.Label><strong>General Observations</strong></Form.Label>
                    <Form.Control as="textarea" rows="6" name="description" value={data.generalObservations} onChange={this.handleInputChange} />
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col md={{ offset: '9' }}>
                    <Accordion.Toggle as={Button} eventKey={'new'} variant="primary" block onClick={this.handleSave} disabled={!this.state.textValue}>
                        Save
                </Accordion.Toggle>
                </Col>
            </Form.Group>

        </Form >
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObservationForm);