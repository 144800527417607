import React, { Component } from "react";
import { Container, Accordion, Card } from "react-bootstrap";
import RemediationForm from "./RemediationForm";
import ListRemediations from "./ListRemediations";
import { ToggleAccordionBtn } from './ToggleAccordionBtn'
export class RemediationControl extends Component {
    render() {
        return <Container><Accordion defaultActiveKey={"new"}>

            <Card>
                <Card.Body>
                    <Card.Title>New Remediation</Card.Title>
                    <ToggleAccordionBtn eventKey="new">New</ToggleAccordionBtn>
                    <Accordion.Collapse eventKey="new">
                        <RemediationForm />
                    </Accordion.Collapse>

                </Card.Body>
            </Card>
            <Card>
                <Card.Body>
                    <Card.Title>Remediations</Card.Title>
                    <ListRemediations />
                </Card.Body>
            </Card>
        </Accordion></Container>
    }
}