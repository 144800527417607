import React, { Component } from 'react'
import { MeetingInfo } from '../common/MeetingInfo'
import { Container } from 'react-bootstrap'
import './Header.css'


export class Header extends Component {
    render() {
        return <Container fluid className="header-container">
            <MeetingInfo info={this.props.info} />
        </Container>
    }
}