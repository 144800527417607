import React, { Component } from 'react';
import Home from './components/Home';
import { Switch, Route } from 'react-router-dom';


export default class App extends Component {
  displayName = App.name

  render() {

    return <Switch>
      <Route path="/" component={Home} />
    </Switch>

  }
}
