import React, { Component } from 'react';
import Loader from './Loader/Loader';
import Jitsi from 'react-jitsi';
import './Call.css';
import { Col, Button, Row } from 'react-bootstrap';


export default class Call extends Component {
    constructor(props) {
        super(props);
        this.state = {
            callDomain: 'jitsi.stro.brightservecloud.com',
            displayName: this.props.display ?? 'Surveyor',
            API: null,
            roomName: this.props.invite
        };
    }


    render() {
        const onCall = this.props.oncall;
        return (onCall) ? <><Jitsi
            containerStyle={{
                width: 'auto', minHeight: '100%'
            }}
            config={{ prejoinPageEnabled: false }}
            roomName={this.state.roomName}
            displayName={this.state.displayName}
            loadingComponent={Loader}
            domain={this.state.callDomain}
            onAPILoad={JitsiMeetAPI => console.log('s')}
        />
            <Row>
                {/* <Col md={{ offset: '10', span: '2' }}>
                    <Button variant="secondary" block>Screenshot</Button>
                </Col> */}
            </Row>
        </>
            : <Loader />

    }
}